.main{
    max-width: 1200px;
    margin: 40px auto;
    padding:0 60px 60px;
}
.imgBox{
    width: 606px;
    height: 329px;
}

.item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 170px;
    max-width: 1170px;
    border-bottom: 1px solid #E8E8E8;
}
.item:hover .h1,
.item:hover .day,
.item:hover .time,
.item:hover .title{
    color: #2b79bd;
}
.time{
    width: 114px;
    height: 125px;
    background: #F1F5F8;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 18px;
    color: #254F75;
    margin-right: 15px;
    font-family: 'Bahnschrift';
}

.day{
    font-size: 50px;
    color: #254F75;
    font-family: 'Bahnschrift';
}
.title{
    flex: 1;
    cursor: pointer;
    max-width: 1010px;
    font-size: 15px;
    color: rgba(110, 110, 110, 0.67);
    padding: 15px;
}
.h1{
    margin-bottom: 18px;
    font-size: 20px;
    color: #292929;
    max-width: 1000px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
}
.newText{
    display: -webkit-box;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;

}


.lunImg{
    width:1200px;
    margin:0 auto;
    display:flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.lb{
    width:606px;
    height:329px;
    margin-right: 53px;
}
.wz{

}
.bt{
    min-height: 29px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 29px;
    color: #292929;
    opacity: 1;
    margin-top: 25px;
    margin-bottom: 33px;
}
.nr{
    min-height: 80px;
    width: 507px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 28px;
    color: rgba(110, 110, 110, 0.67);
    letter-spacing: 2px;
    opacity: 1;
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 33px;
}
.sj{
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 28px;
    color: #1C4263;
    letter-spacing: 2px;
    opacity: .5;
}
.xq{
    display:inline-block;
    width: 224px;
    height: 40px;
    border: 1px solid rgba(152, 152, 152, 0.23137254901960783);
    opacity: 1;
    text-align: center;
    line-height: 40px;
    margin-left: 50px;
    cursor: pointer;
}
.xq:hover{
    color: #fff;
    transition: .5s ease-in-out;
    background: #006cb8;
    border-color: transparent;
}
