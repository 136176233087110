@mixin box() {
  border-radius: 13px;
  overflow: hidden;
  background-color: #F2F5F7;
  border-radius: 13px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  transition: all 1s ease;
  cursor: pointer;

  .box {
    width: 100%;
    height: 100%;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all .5s ease;

    .img {
      margin-bottom: 20px;
    }

    .text {
      margin-top: 20px;
    }
  }
}

.chanpin {
  margin-top: 40px;
  position: relative;

  .lianxi {
    position: absolute;
    right: 0;
    top: 100px;
    cursor: pointer;
  }

  .sanjiao {
    position: absolute;
    left: 0;
    top: 140px;
    cursor: pointer;

    .sanjiao1 {
      position: absolute;
      left: 0;
      top: 100px;
      cursor: pointer;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 70px 0 70px 70px;
      border-color: transparent transparent transparent #387CB894;
    }

    .sanjiao2 {
      position: absolute;
      left: 0;
      top: 60px;
      cursor: pointer;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 40px 0 40px 40px;
      border-color: transparent transparent transparent #E2242678;;
    }
  }

  .title {
    font-size: 24px;
    color: #254F75;
    font-weight: bold;
    text-align: center;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #EAEAEA;

    .titleBottom {
      position: absolute;
      bottom: -3px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      width: 70px;
      background: #fff;

      .b1 {
        width: 24px;
        height: 5px;
        background: #DA4F27;
        margin-right: 3px;
      }

      .b2 {
        width: 24px;
        height: 5px;
        background: #254F75;
      }
    }

  }

  .main {
    width: 100%;
    max-width: 1239px;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px auto;

    .textActive {
      font-size: 14px;
    }

    .left {
      width: 454px;
      height: 476px;
      margin-right: 10px;
      @include box();

      &s {
        width: 454px;
        height: 476px;
        margin-right: 10px;
        @include box();
        background-size: 110% 110%;
        background-image: url("../../../assets/home/bg.png");

        .box {
          transform: translateY(-100%);
        }
      }
    }

    .right {
      .top {
        display: flex;

        .jn {
          margin-right: 10px;
          width: 354px;
          height: 232px;
          @include box();

          &s {
            margin-right: 10px;
            width: 354px;
            height: 232px;
            @include box();
            background-size: 110% 110%;
            background-image: url("../../../assets/home/bg1.png");

            .box {
              transform: translateY(-100%);
            }
          }
        }

        .yw {
          width: 354px;
          height: 232px;
          @include box();

          &s {
            width: 354px;
            height: 232px;
            @include box();
            background-size: 110% 110%;
            background-image: url("../../../assets/home/bg2.png");

            .box {
              transform: translateY(-100%);
            }
          }
        }
      }

      .fx {
        width: 720px;
        height: 232px;
        @include box();
        margin-top: 10px;

        &s {
          width: 720px;
          height: 232px;
          @include box();
          margin-top: 10px;
          background-size: 110% 110%;
          background-image: url('../../../assets/home/bg3.png');

          .box {
            transform: translateY(-100%);
          }
        }
      }
    }

    @mixin Boxbg() {
      width: 298px;
      height: 431px;
      background-repeat: no-repeat;
      position: relative;
      background-size: 100% 100%;
      overflow: hidden;
    }
    @mixin Boxfooter() {
      width: 298px;
      height: 96px;
      max-height: 300px;
      background: rgba(24, 110, 183, 0.85);
      border-radius: 0px 0px 12px 12px;
      position: absolute;
      bottom: 0;
      left: 0;
      &:hover {
        height: 281px;
        transition: all 0.6s ease;

        .text {
          display: block;
          cursor: pointer;
        }
      }
      &:before {
        content: attr(title);
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: 30px;
        height: 100%;
      }
    }
    @mixin BoxfooterImg() {
      width: 57px;
      height: 57px;
      background-repeat: no-repeat;
      position: relative;
      background-size: 100% 100%;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      top: -30px;
    }

    .box1, .box2, .box3, .box4 {
      @include Boxbg();

      .footer {
        @include Boxfooter();

        .tip {
          @include BoxfooterImg();
          background-image: url("../../../assets/home/sthb1.png");
        }

        .text {
          margin-top: 65px;
          display: none;

          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 26px;
          text-align: center;

          padding: 16px;
          box-sizing: border-box;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            background-image: url("../../../assets/home/in.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 75px;
            height: 30px;
            top: 150px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    .box1 {
      background-image: url("../../../assets/home/sthb.png");

      .tip {
        background-image: url("../../../assets/home/sthb1.png");
      }
    }

    .box2 {
      background-image: url("../../../assets/home/jnjp.png");

      .tip {
        background-image: url("../../../assets/home/jnjp1.png");
      }
    }

    .box3 {
      background-image: url("../../../assets/home/ywjc.png");

      .tip {
        background-image: url("../../../assets/home/ywjc1.png");
      }
    }

    .box4 {

      background-image: url("../../../assets/home/dsj.png");

      .tip {
        background-image: url("../../../assets/home/dsj1.png");
      }
    }
  }

  .centetText {
    display: flex;
    justify-content: center;
    color: #254F75;
    font-size: 16px;
  }
}
