.news {
    margin-top: 50px;
    width: 100vw;
    height: 726px;
    background: url("../../../assets/home/newBg.png") no-repeat;
    background-size: 100% 726px;
}
.more {
    margin-top: 6px;
    margin-left: 230px;
    color: rgba(37, 79, 117, 0.8);
    font-size: 16px;
    &:hover {
        color: #254f75;
        cursor: pointer;
    }
}
.title {
    font-size: 24px;
    color: #254F75;
    font-weight: bold;
    text-align: center;
    position: relative;
    padding-top: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #EAEAEA;
}

.titleBottom {
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 70px;
    background: #fff;
}

.b1 {
    width: 24px;
    height: 5px;
    background: #DA4F27;
    margin-right: 3px;
}

.b2 {
    width: 24px;
    height: 5px;
    background: #254F75;
}

.main {
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper {
    width: 510px;
    height: 454px;
    background: #FFFFFF;
    position: relative;
    overflow: hidden;
}

.swiperBox {
    display: flex;
    height: 454px;
    background: #FFFFFF;
    transition: all 1s ease;
}

.swiperImg {
    width: 100%;
    height: 354px;
    object-fit: cover;
}

.textBox {
    padding: 15px 20px;
}

.text {
    width: 100%;
    font-size: 18px;
    color: #083F8F;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.time {
    margin-top: 14px;
    font-size: 16px;
    color: #929292;
}

.dianwei {
    position: absolute;
    right: 40px;
    bottom: 20px;
    display: flex;
    background: #fff;
    z-index: 99999;
}

.dianweiItem {
    width: 23px;
    height: 6px;
    margin-right: 10px;
    background: #EAEAEA;
    cursor: pointer;
}

.dianweiActive {
    width: 23px;
    height: 6px;
    margin-right: 10px;
    background: #083F8F;
}


.newList {
    width: 640px;
    margin-left: 100px;
}

.navs{
    font-size: 16px;
    color: #254F75;
    display: flex;
    border-bottom: 1px solid rgba(112, 112, 112, 0.16);
}
.navItem{
    width: 142px;
    height: 38px;
    background: transparent;
    opacity: 1;
    border-radius: 206px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 16px;
    cursor: pointer;
}
.navItemActive{
    width: 142px;
    height: 38px;
    background: #DA4F27;
    opacity: 1;
    border-radius: 206px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 16px;
    color: #fff;
    cursor: pointer;
}
.newsList{
    height: 380px;
}
.newsItem{
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
    color: #254F75FF;
}
.newsItem:hover{
    transition: .5s ease-in-out;
    background: rgba(0 ,120 ,204 , .82);;
    color: #fff;
}
.newsItem:hover .newText{
    color: #fff;
}
.newsTime{
    font-size: 18px;
    margin-right: 36px;
    transform: translateY(-8px);
}
.day{
    font-size: 60px;
    font-family: 'Bahnschrift';
    text-align: center;
    margin-bottom: 3px;
}
.newMain{
    font-size: 15px;
}
.newTitle{
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 12px;
    width: 440px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.newText{
    width: 440px;
    display: -webkit-box;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(8, 63, 143, 0.67);
}

