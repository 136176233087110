.navs{
    position: absolute;
    left: 50%;
    bottom: 25px;
    transform: translate(-50%,50%);
    width: 100vw;
    height: 88px;
    background: #FFFFFF;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
}
.active{
    font-size: 16px;
    width: 247px;
    height: 88px;
    background: #DA4F27;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
}
.active .text{
    color: #fff;
}
.navItem{
    width: 247px;
    height: 88px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #7595AA;
    font-weight: 200;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    .active999{
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 0;
        background-color: rgba(162,162,162,.1);
        transition: all .2s ease;
    }
    &:hover .active999{
        height: 100%;
    }
}
.head{
    width: 100vw;
    height: 436px;
    position: relative;
    background-repeat:no-repeat;
    position: relative;
}
.leftimg{
    position: absolute;
    right: -135px;
    top: 125%;

}

// about1
.con1{
    width:1200px;
    margin: 0 auto;
}
.con2{
    width:1109px;
    height: 397px;
    margin: 103px auto 300px;
}
.con3{
    padding-top: 40px;
    width: 100vw;
    height: 420px;
    margin: 0 auto;
    text-align: center;
    background-image: url('../../../assets/about/about3.png');
}
.h5wrap{
    background-color: rgba(235, 237, 243, 1);
    .qyjj{
        background-color: rgba(255, 255, 255, 1);
        padding: 0 15px;

        img{
            width: 93vw;
            margin-bottom: 15px;
        }
    }
    .gsjj{
        background-color: rgba(255, 255, 255, 1);
        padding: 0 15px;
        div{
            text-indent: 30px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 24px;
            color: #000000;
            opacity: 1;
            width: 94vw;
        }
    }
    .qymb{
        background-color: rgba(255, 255, 255, 1);
        padding: 0 15px;
        margin-bottom: 15px;
        img{
            width: 93vw;
            margin-top: 25px;
            margin-bottom: 50px;
        }
    }
    .qyfc,.hxtd{
        background-color: rgba(255, 255, 255, 1);
        padding: 15px;
        margin-bottom: 15px;
        h2{
            text-align: center;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #254F75;
            opacity: 1;
        }
        img{
            margin-right: 8px;
            margin-top: -4px;
        }
        div{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .qyfcimg,.hxtdimg{
            width: 93vw;
            margin-right: 0px;
        }
    }
}
// about1

//about2
.about21{
    width: 1200px;
    height: 780px;
    margin: 92px auto 140px;

}
.about22{
    width: 1920px;
    height: 360px;
    margin: 0px auto 0px;
    background-image: url('../../../assets/about/about21.png');
}
.about23{
    width: 1200px;
    height: 330px;
    margin: -190px auto 85px;
    text-align: center;
}
.about23img{
    padding-top: 30px;
    background-color: #FFFFFF;
    height: 208px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    opacity: 1;
    border-radius: 4px;
}
.about24{
    width: 1118px;
    height: 518px;
    margin: 0px auto 120px;

}
.about23Text1{
    margin-top: 10px;
    height: 33px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 33px;
    color: #FFFFFF;
    opacity: 1;
}
.about23Text2{
    margin-top: 22px;
    margin-bottom: 32px;
    height: 25px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 26px;
    color: #FFFFFF;
    opacity: 1;
}
.h5wrap2{
    padding: 0 15px;
    img{
        width: 93vw;
    }
    .rjzz{

        .ryzzTxt{
            background: rgba(39, 64, 103,0.73);
            height: 240px;
            position: relative;
            padding: 0 20px;

            .bg{
                height: 240px;
                background: url('../../../assets/about/H5about/about2bg.svg');
            }
            .txt{
                position: absolute;
                width: 100%;
                top: 20px;
                left: 50%;
                text-align: center;
                transform: translateX(-50%);
                & :nth-of-type(1){
                    height: 25px;
                    font-size: 18px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    line-height: 25px;
                    color: #FFFFFF;
                    opacity: 1;
                }
                & :nth-of-type(2){
                    height: 35px;
                    font-size: 12px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    line-height: 18px;
                    color: #FFFFFF;
                    opacity: 0.81;
                    padding: 0 20px;
                }
            }
        }
        .rjzz{
            transform: translateY(-140px);
        }
        .ryzs{
            margin-top: -130px;
            margin-bottom: 22px;
        }
    }
}
//about2
//about4
.about4{
    width: 1200px;
    margin: 0 auto;
}
.about4 .con41wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 34px;
    margin-bottom: 76px;
}
.con41{
    width: 380px;
    height: 320px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 4px;
    transition: 0.3s all;
    &:hover{
        transform: translateY(15px);
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
    }
}
.con42wrap{
    width: 100%;
    height: 461px;
    margin-bottom: 80px;
}
.con42h1{
    margin-bottom: 24px;
}
.con42h1 .p1{
    width: 120px;
    height: 33px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 33px;
    color: #254F75;
    opacity: 1;
}
.con42h1 .p2{
    width: 336px;
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    opacity: 1;
}
.inputwrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 80px;
}
.input{
    width: 592px;
    height: 70px;
    line-height: 70px;
    background: #F6F6F6;
    opacity: 1;
    border-radius: 2px;
    outline: none;
    border: none;
    text-indent: 60px;
    margin-bottom: 16px;

}
.textarea{
    width: 1200px;
    height: 120px;
    min-width: 1200px;
    line-height: 25px;
    background: rgba(246, 246, 246,0.5);
    opacity: 1;
    outline: none;
    border: none;
    text-indent: 60px;
    margin-bottom: 16px;
    border-radius: 2px;
}
.input::placeholder,.textarea::placeholder {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0,0.5);
}
.button{
    width: 592px;
    height: 70px;
    line-height: 70px;
    background: #64A8E2;
    outline: none;
    border: none;
    margin-bottom: 16px;
    border-radius: 2px;
    cursor: pointer;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 1;
}
.h5wrap4{
    .item4{
        width: 93vw;
        height: 88px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        opacity: 1;
        border-radius: 6px;

        margin: 15px auto;
        dt{
            float: left;
            margin-top: 18px;
            margin-left: 22px;
        }
        dl{
            overflow: hidden;
        }
        .dd1{
            height: 21px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 21px;
            color: #000000;
            opacity: 1;
        }
        .dd2{
            height: 20px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 20px;
            color: #254F75;
            opacity: 1;
        }
    }
}
.h5about4wrap2{
    width: 93vw;
    margin: 0 auto;
    img{
        width: 65vw;
    }
    .h5con2{
        .h5p2{
            height: 20px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 20px;
            color: #666666;
            opacity: 1
        }
    }
    .h5input{
        width: 93vw;
        height: 50px;
        background: #F6F6F6;
        opacity: 1;
        border-radius: 2px;
        outline: none;
        border: none;
        text-indent: 15px;
        margin-bottom: 16px;
        &::placeholder{
            color: rgba(37, 79, 117,0.75);
        }
    }
    .h5textarea{
        width: 93vw;
        height: 120px;
        line-height: 25px;
        background: rgba(246, 246, 246,0.5);
        opacity: 1;
        outline: none;
        border: none;
        text-indent: 15px;
        margin-bottom: 16px;
        border-radius: 2px;
        &::placeholder{
            color: rgba(37, 79, 117,0.75);
        }
    }
    .h5button{
        width: 93vw;
        height: 50px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 50px;
        color: #FFFFFF;
        opacity: 1;
        background: #254F75;
        opacity: 1;
        border-radius: 2px;
        margin-bottom: 24px;
    }
}
#map{
    width: 1920px;
    margin: 0 auto;
    height: 404px;
}
//about4
@media screen and (max-width:414px){
    .con1{
        img{
            width: 100vw  !important;
            height: 100% !important;
        }
    }
    #map{
        width: 93vw !important;
        margin: 0 auto;
        height: 169px !important;
        border-radius: 8px;
        overflow:hidden;
    }
    .con2{
        margin: 20px auto 20px;
        height: auto;
        img{
            width: 100vw  !important;
            height: 100% !important;
        }
    }
    .con3{
        height: 150px;
        background-size: 100vw  100% !important;
        img{
            width: 100vw  !important;
            height: 100% !important;
        }
    }
    .about21{
        img{
            width: 100vw  !important;
            height: 100% !important;
        }
        height: auto;
        margin: 20px auto;
    
    }
    .about22{
        height: 215px;
        background-size: 100vw  100% !important;
        
    }
    .about23{
        width: 100vw;
        height: 340px;
        text-align: center;
        margin: -190px auto 25px;
    }
    .about23img{
        padding-top: 30px;
        background-color: #FFFFFF;
        height: 165px;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
        opacity: 1;
        border-radius: 4px;
        img{
            width: 100vw  !important;
            height: 100% !important;
        }
        width: 346px;
        margin: 0 auto;
        overflow: hidden;
    }
    .about23Text1{
        margin-top: 10px;
        height: 33px;
        font-size: 18px;
        line-height: 33px;
        text-align: center;
    }
    .about23Text2{
        margin-top: 22px;
        margin-bottom: 32px;
        height: 55px;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
    }
    .about24{
        img{
            width: 100vw  !important;
            height: 100% !important;
        }
        height: auto;
        margin: 0px auto 20px;
    }
    .con41wrap{
        display: block !important;
    }
    .con41{
        box-shadow:none;
        width: 414px;
    }
    .con42wrap{
        width: 100vw;
        padding: 0 10px;
        height: auto;
    }
    .inputwrap{
        width: 95vw;
    }
    .textarea{
        width: 95vw;
        min-width: 95vw;
    }
}

