.newhead{
  background-image: url('../../../assets/h5/cpfw.png');
  background-size: 100vw 100%;
  height: 100px;
  width: 90vw;
  border-radius: 5px;
  margin: 0 auto;
  &::before{
    content: '产品服务';
    position: absolute;
    width: 87px;
    height: 28px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    margin-top: 30px;
    margin-left: 50px;
    line-height: 28px;
    color: #FFFFFF;
    opacity: 1;
  }
  &::after{
    content: 'PRODUCTS SERVICES';
    position: absolute;
    height: 14px;
    font-size: 10px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 14px;
    color: #FFFFFF;
    opacity: 1;
    margin-top: 60px;
    margin-left: 50px;
  }
}
.cpfw{
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0px 41px;
  padding-top: 25px;
  margin-bottom: 30px;
  position: relative;
  &::after{
    content: '';
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 90vw;
    height: 1px;
    background:rgba(76, 104, 133, 0.13);
  }
  img{
    margin-bottom: 14px;
  }
  div{
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #254F75;
    opacity: 1;
  }
}
