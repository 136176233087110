.container {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  // border-bottom: 1px solid #E9E9E9;
  & :nth-child(1) {
    width: 50%;
    height: 50%;
    display: block;
    &:nth-of-type(2) {
      cursor: pointer;
    }
  }
}
.wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  position:sticky;
  position: -webkit-sticky;
  background-color: #fff;
  z-index: 100;
  box-shadow:  0px 2px 2px 0px #99999940;
	top:0px;
  margin-bottom: 15px;
}
.item {
  height: 52px;
  line-height: 52px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  opacity: 1;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.active {
  height: 52px;
  line-height: 52px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #254F75;
  opacity: 1;
  text-align: center;
  position: relative;
  cursor: pointer;
}
