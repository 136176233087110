.news {
  margin-top: 20px;
  position: relative;

  .title {
    font-size: 16px;
    color: #254F75;
    font-weight: bold;
    text-align: center;
    position: relative;
    padding-bottom: 10px;

    .titleBottom {
      position: absolute;
      bottom: -3px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      width: 70px;
      background: #fff;

      .b1 {
        width: 24px;
        height: 5px;
        background: #DA4F27;
        margin-right: 3px;
      }

      .b2 {
        width: 24px;
        height: 5px;
        background: #254F75;
      }
    }

  }

  .main {
    width: 100vw;
    padding: 12px;
    background-color: #FFFFFF;
    box-sizing: border-box;
    .swiper {
      width: 100%;
     
      .swiperItem {
        .swiperImg {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          .inner{
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 50%;
            img{
              display: flex;
              position: absolute;
              width: 100%;
              height: 100%;
              justify-content: center;
              align-items: center;
            border-radius: 5px;

            }
          }
        }

        .textBox {
          background: rgba(235, 237, 243, 0.5);
          padding: 15px 20px;
          border-radius: 0 0 10px 10px;
          .text{
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #254F75;
            opacity: 1;
          }
          .time{
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
            opacity: 1;
          }
        }
        @media screen and(max-width:414px){
          .textBox{
            min-height: 98px;
          }
          .text{
            margin-bottom: 10px;
          }
        }
      }
    }

    .newList {
      margin-top: 20px;
      width: 100%;

      .navs {
        width: 100%;
        font-size: 16px;
        color: #254F75;
        display: flex;

        .navItem {
          width: 142px;
          height: 38px;
          background: transparent;
          opacity: 1;
          border-radius: 206px;
          line-height: 38px;
          text-align: center;
          margin-bottom: 16px;
          cursor: pointer;
        }

        .navItemActive {
          width: 142px;
          height: 38px;
          background: #DA4F27;
          opacity: 1;
          border-radius: 206px;
          line-height: 38px;
          text-align: center;
          margin-bottom: 16px;
          color: #fff;
          cursor: pointer;
        }
      }
      .container {
        background-color: #f5f5f5;
        .item {
          padding: 12px;
          cursor: pointer;
          background-color: #fff;
          display: flex;
          justify-content: flex-start;
          .time{
            width: 69px;
            height: 69px;
            background: rgba(235, 237, 243,0.5);
            border-radius: 6px;
            margin-right: 11px;
            text-align: center;

            & :nth-child(1){
              font-size: 25px;
              font-family: PingFang SC;
              font-weight: 800;
              color: #3D4A74;
              opacity: 1;
              padding-top: 3px;
            }
            & :nth-child(2){
              font-size: 11px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #3D4A74;
              opacity: 1;
            }
          }
          .title {
            width: 70vw;
            text-align: left;
            font-size: 18px;
            line-height: 30px;
            color: #222;
            font-weight: 550;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .content {
            width: 65vw;
            font-size: 13px;
            color: #AAAAAA;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          @media screen and(max-width:414px){
            .title{
              font-size: 16px;
            }
          }
        }
      }

    }
  }
  .h5homehzhb{
    width: 100vw;
    height: 186px;
    background: #FFFFFF;
    opacity: 1;
  }
  .h5hometit{
    margin-top: 25px;
    margin-bottom: 14px;
    height: 25px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 25px;
    color: #254F75;
    opacity: 1;
    text-align: center;
  }
}

