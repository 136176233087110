@import '~antd/dist/antd.css';

* {
    margin: 0;
    padding: 0;
    user-select: none;
    box-sizing: content-box;
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
}


@keyframes sk-chase {
    100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot-before {
    50% {
        transform: scale(0.4);
    } 100%, 0% {
          transform: scale(1.0);
      }
}

#root {
    width: 100vw;
    // overflow-x: hidden;
}

img {
    -webkit-user-drag: none;
}

@font-face {
    font-family: bahnschrift;
    src: url('./bahnschrift.ttf');
}

@media screen and (min-width: 1200px) {
    /*banner1*/
    .swiper-pagination.banner1-bullets {
        bottom: 70px;
        left: 0;
        width: 100%;
    }

    .banner1 {
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        background: #fff;
        margin: 0 5px;
    }

    .banner1-active {
        width: 42px;
        height: 8px;
        background: #D14729;
        border-radius: 4px;
    }

    /*banner2*/
    .swiper-pagination.banner2-bullets {
        bottom: 20px;
        left: auto;
        width: auto;
        right: 30px;
    }

    .banner2 {
        width: 23px;
        height: 6px;
        background: #EAEAEA;
        display: inline-block;
        margin: 0 5px;
    }

    .banner2-active {
        background: #083F8F;
    }
}


