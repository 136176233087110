.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px;
  margin-top: 20px;
  margin-bottom: 15px;
  .item {
    width: 90px;
    height: 30px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 30px;
    opacity: 1;
    margin-right: 29px;
    color: #254F75;
    @media screen and(max-width:414px){
      span {
        margin-left: 10px;
      }
    }
  }
  .active {
    width: 90px;
    height: 30px;
    line-height: 30px;
    background: #DA4F27;
    opacity: 1;
    border-radius: 16px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    margin-right: 29px;
    @media screen and(max-width:414px){
      span {
        &:nth-of-type(1) {
          font-size: 16px;
        }
      }
    }
    
  }
}
