@keyframes rings {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(3);
    }
}

.map{
    width: 100vw;
    height: 1031px;
    background: #F2F2F2;
}
.title{
    font-size: 24px;
    color: #254F75;
    display: flex;
    justify-content: center;
    width: 100vw;
    padding-top: 80px;
}
.text{
    text-align: center;
    margin-top: 20px;
    color: #254F75;
}
.mapBox{
    margin: 10px auto;
    width: 1070px;
    height: 820px;
    position: relative;
    background: url("../../../assets/home/map1.png") no-repeat;
}
.map2{
    position: absolute;
    right: -80px;
    bottom: 0;
    width: 171px;
    height: 256px;
    background: url("../../../assets/home/map2.jpg") no-repeat;
}
.dots{
    position: absolute;
    width: 100%;
    height: 100%;
}
.dot{
    position: absolute;
    width: 20px;
    height: 20px;
    transform: translate(-10px,-10px);
    
}
.dot:hover{
    z-index: 99;
}

.dot:hover .content{
    display: block;
    opacity: 1;
}
.dot:hover .ring{
    animation: rings 2.1s linear infinite;
}
.dot:hover .rings:nth-of-type(2) {
    animation: rings 2.1s linear infinite;
    animation-delay: .7s;
}
.dot:hover .rings:nth-of-type(3) {
    animation: rings 2.1s linear infinite;
    animation-delay: 1.4s;
}
.dot1{
    top: 39.56%;
    left: 67.04%;
}
.span1{
    top: 2px;
    left: 120%;
}
.i{
    display: block;
    width: 20px;
    height: 20px;
    background: url('../../../assets/home/icon.png') center no-repeat;
    cursor: pointer;
    animation: rings 2.1s linear infinite;
    animation-delay: 1.4s;
}
.content{
    display: none;
    position: absolute;
    padding-right: 80px;
    right: 0;
    bottom: -10px;
    opacity: 0;
    transition: all 0.2s linear;
}
.icon{
    width: 16px;
    height: 20px;
    background: url('../../../assets/home/icon.svg') center no-repeat;
    background-size: auto 20px;
    position: absolute;
    right: 2px;
    bottom: 20px;
    z-index: 3;
}
.rings{
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #d9edfc;
    position: absolute;
    right: 3px;
    bottom: 13px;
}
.ring{
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border: 1px solid #d9edfc;
    border-radius: 50%;
}
.span{
    font-size: 12px;
    color: #6d83b2;
    position: absolute;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.2s linear;
}
.line{
    width: 71px;
    height: 35px;
    background-position: right top;
    background-repeat: no-repeat;
    background-image: url('../../../assets/home/icon2.png');
    position: absolute;
    right: 9px;
    bottom: 20px;
    z-index: 2;
}
.box{
    padding: 1vw;
    text-align: center;
    background: #108cee;
    box-shadow: 0 10px 20px rgba(16, 140, 238, 0.4);
    position: relative;
    z-index: 3;
}
.titles{
    color: #fff;
    text-align: left;
    line-height: 2;
    font-size: 20px;
}
.p{
    font-size: 14px;
    color: #fff;
    text-align: left;
    white-space: nowrap;
}
