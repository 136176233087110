.wrap{
    width: 1920px;
    margin: 0 auto;
    background-color: #ffffff;
    .and{
        position: absolute;
        left: 973px;
        top: 520px;
        background-image: url('../../../assets/PWK/and.png');
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        width: 153px;
        height: 56px;
        .andm{
            position: absolute;
            left: 170px;
            top: -45px;
            background-image: url('../../../assets/PWK/andm.png');
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
            width: 136px;
            height: 150px;
            opacity: 0;
            transition: opacity 0.8s ease;
        }
        &:hover{
            background-image: url('../../../assets/PWK/andh.png');
            .andm{
                opacity: 1;
            }
        }
    }
    .ios{
        position: absolute;
        left: 794px;
        top: 520px;
        background-image: url('../../../assets/PWK/jj.png');
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        width: 153px;
        height: 56px;
        &:hover{
            background-image: url('../../../assets/PWK/jjh.png');
        }
    }
}
.wrap_pt{
    background-image: url('../../../assets/PWK/bg.png');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 1920px;
    height: 3421px;

}
