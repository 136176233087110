.footer {
    width: 100vw;
    min-height: 344px;
    background: #1C4263;
    color: rgba(255, 255, 255, 0.86);
    position: relative;
}

.main {
    min-height: 344px;
}
.h5Main{
    height: 144px;
    width: 100vw;
    padding: 0 16px;
    overflow: hidden;
    position: relative;
    &::before{
        content: '';
        width: 54px;
        height: 54px;
        background: linear-gradient(232deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
        border-radius: 50%;
        opacity: 0.07;
        position: absolute;
    }
    &::after{
        content: '';
        width: 54px;
        height: 54px;
        background: linear-gradient(232deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
        border-radius: 50%;
        opacity: 0.07;
        position: absolute;
        right: 10px;
        top: 65px;
    }
}
.h5Gs{
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.86);
    opacity: 1;
    margin: 12px auto 5px;
    text-align: center;
}
.h5tit{
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 20px;
    color: rgba(51, 201, 255, 0.86);
    opacity: 1;
    text-align: center;
    margin-bottom: 16px;
}
.h5address,.h5phone{
    display: flex;
    margin-bottom: 7px;
    align-items: baseline;
    div{
        margin-left: 3px;
        height: 34px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 300;
        line-height: 17px;
        color: rgba(255, 255, 255, 0.86);
        opacity: 1;
    }
}
@media screen and (min-width: 1200px) {
    .main {
        width: 80%;
        padding: 0 10%;
    }
}

@media screen and (max-width: 1200px) {
    .main {
        width: 90%;
        padding: 0 5%;
    }
}

.mainHx {
    width: 600px;
}

@media screen and (min-width: 575px) {
    .titie {
        display: flex;
        align-items: center;
        font-size: 19px;
    }
}
@media screen and (max-width: 575px) {
    .titie {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
    }
}
@media screen and (max-width: 414px) {
    .footer {
        background: url('../../assets/h5/footer.svg');
        min-height: 176px;
    }
    .main{
        width: 100vw;
        padding: 0;
        min-height: 144px;
    }
}

.ml {
    margin-left: 10px;
    color: rgba(51, 201, 255, 0.86);
    display: flex;
    align-items: center;
    font-size: 19px;
}

.ml:before {
    display: block;
    content: '·';
    font-size: 30px;
    margin-right: 10px;
}

.jianjie {
    width: 100%;
    display: flex;
    margin-top: 15px;
}

.img {
    width: 130px;
    height: 130px;
    margin-right: 30px;
}

.phone {
    align-items: center;
    margin-top: 30px;
}

.phone,
.address {
    display: flex;
    font-size: 15px;
}

@media screen and (min-width: 575px) {
    .address {
        font-size: 15px;
    }
}
@media screen and (max-width: 575px) {
    .address {
        font-size: 12px;
    }
}

.text {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.41);
    margin-right: 10px;
    width: 40px;
}

.text2 {
    color: rgba(255, 255, 255, 0.86);
}

.mb {
    margin-bottom: 18px;
}


@media screen and (min-width: 575px) {
    .phoneNum {
        font-size: 22px;
    }
}
@media screen and (max-width: 575px) {
    .phoneNum {
        font-size: 18px;
    }
}


.kuozan {
    min-height: 180px;
    margin-left: 10vw;
    width: 310px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}


.title2 {
    font-size: 16px;
    color: #30B6E9;
    margin-bottom: 30px;
}

.listItem {
    cursor: pointer;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.76);
    margin-bottom: 12px;
}
.mainwrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 320px;
}

.huanxin,.huanxin1 {
    width: 100vw;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.huanxin1{
    height: 61px;
}
@media screen and (max-width: 575px) {
    .huanxin {
        font-size: 12px;
    }
}
@media screen and (max-width: 414px) {
    .mainwrap {
        min-height: auto;
    }
}
.mr {
    margin-right: 10px;
}
