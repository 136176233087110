.wrap{
    width: 1920px;
    margin: 0 auto;
    background-color: #ffffff;
    .and{
        position: absolute;
        left: 539px;
        top: 594px;
        background-image: url('../../../assets/TDCR/andh.png');
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        width: 153px;
        height: 56px;
        .andm{
            position: absolute;
            left: 8px;
            top: 60px;
            background-image: url('../../../assets/TDCR/andm.png');
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
            width: 136px;
            height: 150px;
            opacity: 0;
            transition: opacity 0.8s ease;
        }
        &:hover{
            background-image: url('../../../assets/TDCR/and.png');
            .andm{
                opacity: 1;
            }
        }
    }
    .ios{
        position: absolute;
        left: 360px;
        top: 594px;
        background-image: url('../../../assets/TDCR/iosh.png');
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        width: 153px;
        height: 56px;

        .iosm{
            position: absolute;
            left: 8px;
            top: 60px;
            background-image: url('../../../assets/TDCR/iosm.png');
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
            width: 136px;
            height: 150px;
            opacity: 0;
            transition: opacity 0.8s ease;
        }
        &:hover{
            background-image: url('../../../assets/TDCR/ios.png');
            .iosm{
                opacity: 1;
            }
        }
    }
}
.wrap_pt{
    background-image: url('../../../assets/TDCR/bg.png');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 1920px;
    height: 3728px;

}
