@media screen and (max-width: 1200px) {
  .newsList {
    display: none;
    opacity: 0;
  }
}

.banners {
  position: relative;
  height: 32vw;
  overflow: hidden;
  background: #f0f0f0b8;

}

.box {
  width: 1100px;
  margin: 0 auto;
  padding-top: 7.5vw;
}

.img {
  width: 100vw;
  height: 32vw;
}

.banner {
  display: flex;
  transition: all 1s ease;
}

.bannerItem {
  width: 100vw;
}

.newsList {
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 54px;
  z-index: 9;
  background: rgba(28, 66, 99, 0.72);
  display: flex;
  align-items: center;
  justify-content: center;
}


.title {
  width: 300px;
  height: 54px;
  position: relative;
  z-index: 1;
  background: url('../../../assets/home/gonggaobg1.png');
}

.title:after {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 300px;
  height: 54px;
  transform: translateX(10px);
  background: url('../../../assets/home/gonggaobg2.png');
}

.text {
  position: absolute;
  z-index: 99;
  line-height: 54px;
  padding-left: 180px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}


.news {
  width: 800px;
  height: 54px;
  line-height: 54px;
  margin-left: 30px;
  color: #fff;
  overflow: hidden;
}
.video{
  //background: #ddd url('/public/swiper.png');
  background-size: 100vw 32vw;;
}
.newsBox {
  transition: all 1s ease;
}

.BannerList {
  position: relative;
  &:hover .navBoxleft3{
      display: flex;
  }

  .navBoxleft3 {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    padding: 0 20px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    //display: none;

    .navBoxleft4 {
      width: 40px;
      height: 40px;
      background-size: 100% 100%;
      opacity: 0.3;
      background-image: url("../../../assets/image/left.png");

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }

    .navBoxleft5 {
      width: 40px;
      height: 40px;
      background-size: 100% 100%;
      transform: rotate(180deg);
      opacity: 0.3;
      background-image: url("../../../assets/image/left.png");

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
}

.navBoxleft {
  display: flex;
  justify-content: space-between;
  width: 50px;
  position: relative;
  z-index: 9999;

  .navBoxleft1 {
    width: 18px;
    height: 18px;
    background-size: 100% 100%;
    opacity: 0.3;
    background-image: url("../../../assets/image/left.png");

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  .navBoxleft2 {
    width: 18px;
    height: 18px;
    background-size: 100% 100%;
    transform: rotate(180deg);
    opacity: 0.3;
    background-image: url("../../../assets/image/left.png");

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}
