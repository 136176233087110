.hezuo{
    width: 100vw;
    margin:  88px auto 160px auto;
    position: relative;
}
.title{
    text-align: center;
    font-size: 24px;
    color: #254F75;
    margin-bottom: 16px;
}
.text{
    text-align: center;
    font-size: 15px;
    color: rgba(37, 79, 117, 0.59);
}
.img{
    display: block;
    margin: 110px auto 0 auto;
}
.icon{
    position: absolute;
    right: 0;
    top: 40px;
    transform:translateX(50%);
}
