

.chanpin {
  margin-top: 20px;
  position: relative;

  .title {
    font-size: 16px;
    color: #254F75;
    font-weight: bold;
    text-align: center;
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #EAEAEA;

    .titleBottom {
      position: absolute;
      bottom: -3px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      width: 70px;
      background: #fff;

      .b1 {
        width: 24px;
        height: 5px;
        background: #DA4F27;
        margin-right: 3px;
      }

      .b2 {
        width: 24px;
        height: 5px;
        background: #254F75;
      }
    }

  }

  .boxs{
    width: 100vw;
    box-sizing: border-box;
    padding: 12px;
    border-radius: 8px;

    .box{
      width: 100%;
      padding: 20px;
      margin-bottom: 20px;
      box-sizing: border-box;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 8px;
      color: #fff;
      &:nth-child(1){
        background-image: url("../../../assets/home/bg.png");
      }
      &:nth-child(2){
        background-image: url("../../../assets/home/bg1.png");
      }
      &:nth-child(3){
        background-image: url("../../../assets/home/bg2.png");
      }
      &:nth-child(4){
        background-image: url("../../../assets/home/bg3.png");
      }
      .textActive{
        padding: 30px 0;
      }
    }
  }
}
.h5homebox{
  width: 92vw;
  padding: 0 13px;
  text-align: center;
  .homeimg{
    padding: 0;
    width: 94vw;
    height: 100%;
  }
}
.ggwrap{
  width: 100vw;
  margin-top: 19px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 28px;
  overflow: hidden;
  img{
    margin-right: 11px;
    vertical-align: baseline;
  }
}
.cpfw{
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0 15px;
  padding-top: 25px;
  border-top: 1px solid rgba(76, 104, 133, 0.13);
  margin-bottom: 30px;
  img{
    margin-bottom: 14px;
  }
  div{
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #254F75;
    opacity: 1;
  }
}
.h5new{
  width: 69vw;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #254F75;
  opacity: 1;
  transform: translateY(-8px);
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
