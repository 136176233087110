.wrap{
    width: 1920px;
    margin: 0 auto;
}
.wrap_sy{
    background-image: url('../../../assets/plus/sy@2x.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 884px;
    position: relative;
    .page3{
        position: absolute;
        top: 336px;
        left: 360px;
        background-image: url('../../../assets/plus/page3@2x.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        width: 436px;
        height: 194px;
    }
    .az{
        position: absolute;
        top: 570px;
        left: 360px;
        background-image: url('../../../assets/plus/az@2x.png');
        background-size: 100%;
        background-repeat: no-repeat;
        width: 162px;
        height: 48px;
        &:hover{
            position: absolute;
            top: 570px;
            left: 360px;
            background-image: url('../../../assets/plus/azh@2x.png');
            background-size: 100%;
            background-repeat: no-repeat;
            width: 162px;
            height: 48px;
            &::before{
                position: absolute;
                content: '';
                top: 60px;
                left: 5px;
                background-image: url('../../../assets/plus/azm@2x.png');
                background-size: 100%;
                background-repeat: no-repeat;
                width: 148px;
                height: 158px;
                z-index: 2;
            }
        }
    }
    .pg{
        position: absolute;
        top: 570px;
        left: 564px;
        background-image: url('../../../assets/plus/pg@2x.png');
        background-repeat: no-repeat;
        background-size: 100%;
        width: 162px;
        height: 48px;
        &:hover{
            position: absolute;
            top: 570px;
            left: 564px;
            background-image: url('../../../assets/plus/pgh@2x.png');
            background-size: 100%;
            background-repeat: no-repeat;
            width: 162px;
            height: 48px;
            &::before{
                position: absolute;
                content: '';
                top: 60px;
                left: 5px;
                background-image: url('../../../assets/plus/pgm@2x.png');
                background-size: 100%;
                background-repeat: no-repeat;
                width: 148px;
                height: 158px;
                z-index: 2;
            }
        }
    }
}
.wrap_pt{
    background-image: url('../../../assets/plus/page3.jpg');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 2240px;
}
