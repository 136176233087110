.animated {
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}
.animated-slow {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -50px, 0);
        transform: translate3d(0, -50px, 0)
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -50px, 0);
        transform: translate3d(0, -50px, 0)
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown
}

@-webkit-keyframes fadeOutDown {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

@keyframes fadeOutDown {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown
}

@-webkit-keyframes fadeOutUp {
    from {
        opacity: 1
    }
    to {
        display: none;
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

@keyframes fadeOutUp {
    from {
        opacity: 1
    }
    to {
        display: none;
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
}

.fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
}
.head{
    width: 100vw;
    height: 436px;
    position: relative;
    //background: url('public/banner.png');
    position: relative;
}
.navs{
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%,50%);
    width: 988px;
    height: 111px;
    background: #FFFFFF;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
    background: #FFFFFF;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
}
.title{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    font-size: 18px;
    font-weight: 200;
}
.text{
    color: #B7B7B7;
}
.gird{
    width: 1200px;
    margin: 52px auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 30px;
}
.item{
    cursor: pointer;
    width: 375px;
    height: 405px;
    background: #fff;
    box-shadow: 0 2px 35px 0 #C9C9C9;
    margin:0 auto;
}
.img{
    width: 356px;
    height: 308px;
    box-sizing: border-box;
    text-align: center;
    overflow: hidden;
    margin: 11px auto 0;
    overflow: hidden;
}
.img img{
    width: 356px;

    transition: all 0.5s;
    height: 308px;
}
.img:hover img{
    transform: scale(1.1);
}
.name{
    text-align: center;
    line-height: 70px;
    font-size: 17px;
    padding: 0 20px;
    width: 335px;
    text-overflow:ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.bannerTitle{
    position: absolute;
    width:576px;
    height:90px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: -200px;
}
.bannerTitle .p1span1{
    height: 40px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 40px;
    color: #FFFFFF;
    opacity: 1;
    margin-right: 24px;
}
.bannerTitle .p1span2{
    height: 40px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 40px;
    color: #FFFFFF;
    opacity: 1;
}
.bannerTitle .p2{
    width: 336px;
    height: 0px;
    border: 1px solid rgba(255, 255, 255, 0.3294117647058823);
    opacity: 1;
    margin-top:11px;
    margin-bottom:17px;
}
.bannerTitle .p3{
    width: 576px;
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 300;
    line-height: 22px;
    color: #FFFFFF;
    opacity: 1;
}
.slideInEllipticLeftFwd {
	-webkit-animation: slide-in-elliptic-left-fwd 1s ease-in-out both;
	        animation: slide-in-elliptic-left-fwd 1s ease-in-out both;
}

 @-webkit-keyframes slide-in-elliptic-left-fwd {
    0% {
      -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
              transform: translateX(-800px) rotateY(30deg) scale(0);
      -webkit-transform-origin: -100% 50%;
              transform-origin: -100% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) rotateY(0) scale(1);
              transform: translateX(0) rotateY(0) scale(1);
      -webkit-transform-origin: 1800px 50%;
              transform-origin: 1800px 50%;
      opacity: 1;
    }
  }
  @keyframes slide-in-elliptic-left-fwd {
    0% {
      -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
              transform: translateX(-800px) rotateY(30deg) scale(0);
      -webkit-transform-origin: -100% 50%;
              transform-origin: -100% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) rotateY(0) scale(1);
              transform: translateX(0) rotateY(0) scale(1);
      -webkit-transform-origin: 1800px 50%;
              transform-origin: 1800px 50%;
      opacity: 1;
    }
  }
  .slideInEllipticRightBck {
	-webkit-animation: slide-in-elliptic-right-bck 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-elliptic-right-bck 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-elliptic-right-bck {
        0% {
          -webkit-transform: translateX(800px) rotateY(30deg) scale(6.5);
                  transform: translateX(800px) rotateY(30deg) scale(6.5);
          -webkit-transform-origin: -100% 50%;
                  transform-origin: -100% 50%;
          opacity: 0;
        }
        100% {
          -webkit-transform: translateX(0) rotateY(0) scale(1);
                  transform: translateX(0) rotateY(0) scale(1);
          -webkit-transform-origin: 600px 50%;
                  transform-origin: 600px 50%;
          opacity: 1;
        }
      }
      @keyframes slide-in-elliptic-right-bck {
        0% {
          -webkit-transform: translateX(800px) rotateY(30deg) scale(6.5);
                  transform: translateX(800px) rotateY(30deg) scale(6.5);
          -webkit-transform-origin: -100% 50%;
                  transform-origin: -100% 50%;
          opacity: 0;
        }
        100% {
          -webkit-transform: translateX(0) rotateY(0) scale(1);
                  transform: translateX(0) rotateY(0) scale(1);
          -webkit-transform-origin: 600px 50%;
                  transform-origin: 600px 50%;
          opacity: 1;
        }
      }

.box{
    width: 100%;
    height: 88px;
    border-bottom: 1px solid #DFDFDF;
}
.nav{
    width: 988px;
    display: flex;
    align-items: center;
    margin: 0 auto;
}
.navItem{
    width: 247px;
    height: 111px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #7595AA;
    font-weight: 200;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    .active999{
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 0;
        background-color: rgba(162,162,162,.1);
        transition: all .2s ease;
    }
    &:hover .active999{
        height: 100%;
    }
}
.active img,
.navItem img{
    width: 30px;
    height: 30px;
    margin-bottom: 10px;
}
.active{
    font-size: 16px;
    width: 247px;
    height: 111px;
    background: #DA4F27;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
}
.active .text{
    color: #fff;
}
.active .textimg{
    margin-bottom: 16px;
}
.textimg{
    margin-bottom: 16px;
}
.text{
    font-size: 14px;
    color: #DADADA;
}
.imgs{
    display: block;
    margin: 100px auto;
    width: 1000px;
}

.connect{
    width: 1920px;
    height: 241px;
    background: rgba(0, 0, 0, 0);
    opacity: 1;
}
.connectImg{
    background: url('../../../assets/image/connect.png') no-repeat center;
    width:100vw;
    height: 100%;
    overflow: hidden;
}
.connectText{
    width: 736px;
    height: 33px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 33px;
    color: #FFFFFF;
    opacity: 1;
    margin: 69px auto 49px;
}
#xm,#dh,#yx{
    width: 263px;
    height: 41px;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.47843137254901963);
    opacity: 1;
    border-radius: 6px;
    outline: none;
    background: transparent;
    text-indent: 20px;
    margin-right: 16px;
    line-height: 41px;
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
}
#submit{
    width: 116px;
    height: 41px;
    border: 1px solid rgba(255, 255, 255, 0.47843137254901963);
    opacity: 1;
    border-radius: 6px;
    outline: none;
    background: transparent;
    margin-left: 23px;
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
}

::-webkit-input-placeholder{
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
}
.formInput{
    width:1000px;
    margin:0 auto;
}
.listH5wrap{
    .h5list{
        width: 90vw;
        margin: 0 auto;
        .listitem{
            margin-bottom: 28px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            
        .listimg{
            margin-right: 10px;
            width: 25vw;
            border-radius: 4px;
        }
        .listtxt{
            width: 60vw;
            .tit{
                height: 22px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 800;
                line-height: 22px;
                color: #254F75;
                opacity: 1;
                margin-bottom: 4px;
            }
            .txt{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                font-size: 13px;
                font-family: PingFang SC;
                font-weight: 500;
                line-height: 18px;
                color: #AAAAAA;
                opacity: 1;
            }
        }
        }
    }
}
.btns1{
    width:95vw;
    height: 38px;
    margin: 0 auto;
    margin-bottom: 10px;
    color: #3D4A74;
    text-align: center;
    line-height: 38px;
    background: rgba(204, 209, 225, 0.3);
    border-radius: 8px;
    margin-top: 10px;
    &:hover{
      cursor: pointer;
      background: #1f609e;
      color: #fff;
    }
  }
@media screen and (max-width:1200px){
    .nav {
      width:600px;
    }
    .navs{
        width:600px;
      transition:all 0.5s;

    }
    .gird{
        transition:all 0.5s;
        width:900px;
        grid-template-columns: repeat(2,1fr);
    }
}
@media screen and (max-width:1000px){
    .gird{
        transition:all 0.5s;
        width:auto;
        grid-template-columns: repeat(1,1fr);
    }
    
}
@media screen and (max-width:414px){
    .navs{
        bottom:-44px;
        width:414px;
    }
    .nav{
        width:414px;
    }
    .head{
        height:32vw;
    }
    .navItem,.active{
        width:25%;
        font-size:12px;
    }
    .text{
        font-size:12px;
    }
    .connect{
        width: 100vw;
        height: 241px;
        background: rgba(0, 0, 0, 0);
        opacity: 1;
    }
    .connectImg{
        background: url('../../../assets/image/connect.png') no-repeat center;
        width:100vw;
        height: 100%;
        overflow: hidden;
    }
    .connectText{
        width: 360px;
        height: 33px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 33px;
        color: #FFFFFF;
        opacity: 1;
        margin: 69px auto 49px;
    }
    #xm,#dh,#yx{
        width: 360px;
        height: 41px;
        box-sizing: border-box;
        border: 1px solid rgba(255, 255, 255, 0.47843137254901963);
        opacity: 1;
        border-radius: 6px;
        outline: none;
        background: transparent;
        text-indent: 20px;
        line-height: 41px;
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        text-align: left;
        margin-bottom: 10px;
    }
    #submit{
        width: 116px;
        height: 41px;
        border: 1px solid rgba(255, 255, 255, 0.47843137254901963);
        opacity: 1;
        border-radius: 6px;
        outline: none;
        background: transparent;
        margin-left: 23px;
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
    }
    
    ::-webkit-input-placeholder{
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
    }
    .formInput{
        width:390px;
        height: 90px;
        overflow-y: auto;
        overflow-x: hidden;
        text-align: center;
    }
    .title{
        margin-top:0px;
    }
}
