.box{
    width: 100%;
    height: 88px;
    border-bottom: 1px solid #DFDFDF;
}

.btns{
  width: 1200px;
  height: 58px;
  margin: 0 auto;
  margin-bottom: 100px;
  color: #1C4263;
  text-align: center;
  line-height: 58px;
  background: #F6F6F6;
  &:hover{
    cursor: pointer;
    background: #1f609e;
    color: #fff;
  }
}
.btns1{
  width:95vw;
  height: 38px;
  margin: 0 auto;
  margin-bottom: 10px;
  color: #3D4A74;
  text-align: center;
  line-height: 38px;
  background: rgba(204, 209, 225, 0.3);
  border-radius: 8px;
  margin-top: 10px;
  &:hover{
    cursor: pointer;
    background: #1f609e;
    color: #fff;
  }
}
.nav{
    max-width: 1200px;
    display: flex;
    align-items: center;
    margin: 0 auto;
}
@keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
.navItem{
    width: 253px;
    height: 89px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #333;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    .active999{
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 0;
        background-color: rgba(162,162,162,.1);
        transition: all .2s ease;
    }
    &:hover .active999{
        height: 100%;
    }
}
.active{
    font-size: 16px;
    width: 253px;
    height: 89px;
    background: #DA4F27;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
}
.active .text{
    color: #fff;
}
.text{
    font-size: 14px;
    color: #DADADA;
    margin-top: 6px;
}
.bannerTitle{
    position: absolute;
    width: 576px;
    height: 90px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: -300px;
}
.bannerTitle .p1span1{
    height: 40px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 40px;
    color: #FFFFFF;
    opacity: 1;
    margin-right: 24px;
}
.bannerTitle .p1span2{
    height: 40px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 800;
    line-height: 40px;
    color: #FFFFFF;
    opacity: 1;
}
.bannerTitle .p2{
    width: 336px;
    height: 0px;
    border: 1px solid rgba(255, 255, 255, 0.3294117647058823);
    opacity: 1;
    margin-top:11px;
    margin-bottom:17px;
}
.bannerTitle .p3{
    width: 576px;
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 300;
    line-height: 22px;
    color: #FFFFFF;
    opacity: 1;
}
.slideInEllipticLeftFwd {
	-webkit-animation: slide-in-elliptic-left-fwd 1s ease-in-out both;
	        animation: slide-in-elliptic-left-fwd 1s ease-in-out both;
}

 @-webkit-keyframes slide-in-elliptic-left-fwd {
    0% {
      -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
              transform: translateX(-800px) rotateY(30deg) scale(0);
      -webkit-transform-origin: -100% 50%;
              transform-origin: -100% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) rotateY(0) scale(1);
              transform: translateX(0) rotateY(0) scale(1);
      -webkit-transform-origin: 1800px 50%;
              transform-origin: 1800px 50%;
      opacity: 1;
    }
  }
  @keyframes slide-in-elliptic-left-fwd {
    0% {
      -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
              transform: translateX(-800px) rotateY(30deg) scale(0);
      -webkit-transform-origin: -100% 50%;
              transform-origin: -100% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0) rotateY(0) scale(1);
              transform: translateX(0) rotateY(0) scale(1);
      -webkit-transform-origin: 1800px 50%;
              transform-origin: 1800px 50%;
      opacity: 1;
    }
  }
  .slideInEllipticRightBck {
	-webkit-animation: slide-in-elliptic-right-bck 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-elliptic-right-bck 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-elliptic-right-bck {
        0% {
          -webkit-transform: translateX(800px) rotateY(30deg) scale(6.5);
                  transform: translateX(800px) rotateY(30deg) scale(6.5);
          -webkit-transform-origin: -100% 50%;
                  transform-origin: -100% 50%;
          opacity: 0;
        }
        100% {
          -webkit-transform: translateX(0) rotateY(0) scale(1);
                  transform: translateX(0) rotateY(0) scale(1);
          -webkit-transform-origin: 600px 50%;
                  transform-origin: 600px 50%;
          opacity: 1;
        }
      }
      @keyframes slide-in-elliptic-right-bck {
        0% {
          -webkit-transform: translateX(800px) rotateY(30deg) scale(6.5);
                  transform: translateX(800px) rotateY(30deg) scale(6.5);
          -webkit-transform-origin: -100% 50%;
                  transform-origin: -100% 50%;
          opacity: 0;
        }
        100% {
          -webkit-transform: translateX(0) rotateY(0) scale(1);
                  transform: translateX(0) rotateY(0) scale(1);
          -webkit-transform-origin: 600px 50%;
                  transform-origin: 600px 50%;
          opacity: 1;
        }
      }
      .Navheader{
        width: 100vw;
        height: 23vw;
        position: relative;
        background-size: 100% 100%;
      }
      .Navheader1{
        width: 90vw;
        height: 100px;
        background: url('../../../assets/h5/xwdt.png');
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        background-size: 90vw 100px;
        &::before{
          content: '环信动态';
          position: absolute;
          width: 87px;
          height: 28px;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          margin-top: 30px;
          margin-left: 50px;
          line-height: 28px;
          color: #FFFFFF;
          opacity: 1;
        }
        &::after{
          content: 'HUAN XIN NEWS';
          position: absolute;
          height: 14px;
          font-size: 10px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 14px;
          color: #FFFFFF;
          opacity: 1;
          margin-top: 60px;
          margin-left: 50px;
        }
      }
      .detailWrap{
        position: relative;
        width: 1166px;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        opacity: 1;
        z-index: 1;
        border-radius: 4px;
        margin: 0 auto;
        margin-top: -125px;
        margin-bottom: 40px;
        padding: 0 16px 20px 16px;
        overflow: hidden;
      }

      .detailnav{
        height: 55px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: rgba(102, 102, 102, 1);
        opacity: 1;
        margin-top: 20px;
      }
      .detailnav .dqlocation{
        color:rgba(218, 79, 39, 1);
      }
      .detailContent{
        width: 100%;
        height: 500px;
        overflow: hidden;
      }
      .title{
        text-align: center;
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #292929;
        opacity: 1;
      }
      .time{
        text-align: center;
        height: 19px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 28px;
        color: #666666;
        letter-spacing: 2px;
        opacity: 1;
        margin-top: 12px;
        margin-bottom: 65px;
      }
      .more{
        width: 1166px;
        height: 58px;
        line-height: 58px;
        background: #F6F6F6;
        opacity: 1;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #1C4263;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 66px;
        cursor: pointer;
      }
      .more:hover{
        color: #fff;
        transition: .5s ease-in-out;
        background: #006cb8;
        border-color: transparent;
      }
      .inner{
        width: 533px;
        height: 100%;
        border-radius: 5px;
        margin-bottom: 30px;
      }
      @media screen and(max-width:414px) {
        .detailWrap{
          width: auto;
          margin-top: 0;
          min-height: 100vh;
        }
        .title{
          height: auto;
          font-size: 16px;
        }
        .detailnav{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .time{
          margin-bottom: 10px;
          margin-top: 6px;
        }
        .inner{
          margin-bottom: 10px;
        }
      }
