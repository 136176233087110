.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px;
  margin-top: 20px;
  border-bottom: 1px solid #dadada;
  padding-bottom: 15px;
  .item {
    // width: 90px;
    height: 25px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 25px;
    opacity: 1;
    padding: 2px 10px;
    color: #254F75;
  }
  .active {
    // width: 90px;
    height: 25px;
    line-height: 25px;
    background: #DA4F27;
    opacity: 1;
    border-radius: 16px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    padding: 2px 10px;
  }
}
.imgabout{
  background-image: url('../../../assets/h5/gyhx.png');
  background-size: 100vw 100%;
  height: 100px;
  width: 90vw;
  border-radius: 5px;
  margin: 0 auto;
  &::before{
    content: '关于环信';
    position: absolute;
    width: 87px;
    height: 28px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    margin-top: 30px;
    margin-left: 50px;
    line-height: 28px;
    color: #FFFFFF;
    opacity: 1;
  }
  &::after{
    content: 'ABOUT';
    position: absolute;
    height: 14px;
    font-size: 10px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 14px;
    color: #FFFFFF;
    opacity: 1;
    margin-top: 60px;
    margin-left: 50px;
  }
}
