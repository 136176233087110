.container {
  background-color: #f5f5f5;
  border-top: 1px solid rgba(76, 104, 133, 0.2);
  margin-top: 20px;
  .item {
    padding: 12px;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    .time{
      width: 69px;
      height: 69px;
      background: rgba(235, 237, 243,0.5);
      border-radius: 6px;
      margin-right: 11px;
      text-align: center;

      & :nth-child(1){
        font-size: 25px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #3D4A74;
        opacity: 1;
        padding-top: 3px;
      }
      & :nth-child(2){
        font-size: 11px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #3D4A74;
        opacity: 1;
      }
    }
    .title {
      width: 70vw;
      text-align: left;
      font-size: 18px;
      line-height: 30px;
      color: #222;
      font-weight: 550;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .content {
      width: 65vw;
      font-size: 13px;
      color: #AAAAAA;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    @media screen and(max-width:414px){
      .title{
        font-size: 16px;
      }
    }
  }
}
.swiper {
  width: 100%;
 
  .swiperItem {
    .swiperImg {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      .inner{
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 50%;
        width: 90vw;
        height: 0;
        padding-bottom: 50%;
        overflow: hidden;
        border-radius: 8px;
        margin: 0 auto;
        img{
          position: absolute;
          width: 414px;
          height: 100%;
          
        }
      }
    }

    .textBox {
      padding: 15px 20px;
      border-radius: 0 0 10px 10px;
      .text{
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #254F75;
        opacity: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .content{
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 21px;
        color: #999999;
        opacity: 1;
        margin-top: 10px;
        margin-bottom: 14px;
      }
      .detailwrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .detail{
          width: 105px;
          height: 30px;
          background: #FFFFFF;
          border: 1px solid #254F75;
          border-radius: 15px;
          text-align: center;
          line-height: 30px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #254F75;
          opacity: 1
        }
        .time{
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color:#254F75;
          opacity: 1;
        }
      }
    }
  }
}