.about3{
    width:60vw;
    margin:0 auto;
}
.tit{
    height: 116px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 116px;
    color: rgba(163, 163, 163, 0.15);
    opacity: 1;
    margin-top:26px;
    margin-bottom:15px;
    position: relative;
}
.etext{
    height: 116px;
    font-size: 83px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 116px;
    color: rgba(163, 163, 163, 0.1);
    opacity: 1;
}
.ctext{
    width: 96px;
    height: 33px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 33px;
    color: #254F75;
    opacity: 1;
    position: absolute;
    top:45px;
    left:10px;
}
.wenzi{
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 26px;
    color: #333333;
    opacity: 1;
    margin-bottom:41px;
    
}
.swiper1{
    height: 320px;
    background: #FFFFFF;
    border: 1px solid #6EB1E9;
    opacity: 1;
    border-radius: 4px;
    text-align: center;
    margin-right: 12px;
    margin-bottom:20px;
    box-sizing: border-box;
    padding: 0 10px;
    &:hover{
        cursor: pointer;
        border: 1px solid rgba(110 ,177 ,233,.5);
        background:rgba(110 ,177 ,233,.2)
    }
}
.swiper1 img{
    width: 100%;
    transform: scale(0.6);
    margin-top:50px;
}
.swipername{
    width:330px;
    text-align: center;
    height: 28px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 28px;
    color: #1C4263;
    opacity: 1;
}
.swiperWrap{
    margin: 0 auto;
    width: 1000px;
    position: relative;
    margin-bottom: 96px;
}
.navBoxleft {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    position: absolute;
    top: 100%;
    height: 400px;
    transform: translate(-100px,-100%);
    z-index: 0;
    .hoverQY{
        padding-top: 200px;
        &:hover .navBoxleft1,
        &:hover .navBoxleft2{
            cursor: pointer;
            opacity: 1;
        }
        .navBoxleft1 {
            width: 30px;
            height:  30px;
            background-size: 100% 100%;
            opacity: 0.3;
            background-image: url("../../../assets/image/left1.png");
        }
        .navBoxleft2 {
            width:  30px;
            height:  30px;
            background-size: 100% 100%;
            transform: rotate(180deg);
            opacity: 0.3;
            background-image: url("../../../assets/image/left1.png");
        }
    }

}


//zhaopin
.zpwrap{
    width: 1200px;
    margin: 0 auto;
}
.zptit{
    width: 1200px;
    height: 160px;
    background: #EEF1F9;
    opacity: 1;
    margin: 0 auto;
    padding-top: 20px;
}
.zptitwrap,.zpyq{
    width: 1200px;
    margin: 0 auto;
}
.zptitle{
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #DA4F27;
    opacity: 1;
    margin-bottom: 22px;
}
.name{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.namebox{
    margin-bottom: 21px;
}
.namebox span{
    height: 33px;
    font-family: PingFang SC;
    font-weight: bold;
    opacity: 1;
}
.namebox span:nth-of-type(1){
    font-size: 24px;
    line-height: 33px;
    color: #2E81C7;
    margin-right: 22px;
}
.namebox span:nth-of-type(2){
    font-size: 16px;
    line-height: 22px;
    color: rgba(241, 105, 83, 1);
}
.zpbutton{
    width: 108px;
    line-height: 40px;
    background: #DA4F27;
    border-radius: 4px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 1;
    outline: none;
    border: none;
    cursor: pointer;
    a{
        color: #FFFFFF;
    }
    a:hover{
        color: #FFFFFF;
    }
}

.zpaddress{
    height: 25px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 25px;
    color: #000000;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.zpaddress span:nth-of-type(2){

    height: 25px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 25px;
    color: #666666;
    opacity: 1;
}
.zwms,.gwyq{
    margin-top: 70px;
    margin-left: 16px;
    height: 28px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 28px;
    color: #000000;
    opacity: 1;
    position: relative;
}
.zwms::before,.gwyq::before{
    content: '';
    position: absolute;
    width: 4px;
    height: 20px;
    background: #2E81C7;
    opacity: 1;
    border-radius: 6px;
    top: 5px;
    left: -15px;
}
.msnr{
    width: 1200px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 40px;
    color: #333333;
    opacity: 1;
    margin: 0 auto;
    margin-bottom: 55px;
}
//zhaopin
@media screen and(max-width:414px) {
    .about3{
        width: 90vw;
    }
    .etext{
        font-size: 38px;
    }
    .swiperWrap{
        width: 90vw;
        margin-bottom: 0;
    }
    .zpwrap{
        width: 100vw;
        margin: 0 auto;
    }
    .swiper1{
        border: none;
        height: 300px;
        &:hover{
            border: none;
            background: none;
        }
    }
    .swiper1 img{
        margin-top:-40px;
    }
    .tit{
        margin: 0;
        height: 42px;
        line-height: 42px;
        margin-top: 10px;
        .etext{
            height: 0;
            line-height: 0;
        }
        .ctext{
            top: 0px;
        }
    }
    .swipername{
        width: 145px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 36px;
        color: #1C4263;
        opacity: 1;
        height: 36px;
        background: #EBEDF3;
        border: 1px solid #254F75;
        text-align: center;
        margin: 0 auto;
        position: relative;
        &::before{
            content: '';
            position: absolute;
            height: 36px;
            width: 145px;
            top: 5px;
            left: 5px;
            border: 1px solid rgba(37, 79, 117, 1);
        }
    }
    .zptitle{
        padding: 0 10px;
    }
    .name{
        width: 90vw;
        padding: 0 10px;
    }
    .namebox span:nth-of-type(1){
        font-size: 16px;
    }
    .namebox span:nth-of-type(2){
        font-size: 16px;
    }
    .zpbutton{
        width: 90px;
        font-size: 16px;
        height: 30px;
        line-height: 30px;
        margin-bottom: 15px;
    }
    
    .zpaddress{
        width: 90vw;
        font-size: 14px;
        padding: 0 10px;
    }
    .zpaddress span:nth-of-type(2){
        font-size: 12px;
    }
    .msnr{
        // width: 90vw;
        padding: 0 16px;
        width: 90vw
    }
    .wenzi{
        height: 51px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 17px;
        color: #254F75;
        opacity: 1;
    }
    .zpyq{
        width: 90vw;
    }
    .navBoxleft{
        display: flex;
        justify-content: space-between;
        width: 100vw;
        position: absolute;
        top: 100%;
        height: 400px;
        transform: translate(-24px, -77%);
        z-index: 0;
    }
}